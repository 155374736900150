import ProductListPage from 'Component/ProductListPage';

import { PAGE_TYPE_CATEGORY } from '../component/GoogleTagManager/GoogleTagManager.config';
import { EVENT_GTM_DYNAMIC_RETARGETING }
from '../component/GoogleTagManager/GoogleTagManager.events';
import { event, setPageType } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        setPageType: (pageType) => dispatch(setPageType(pageType))
    };
};

const componentDidMount = (args, callback, instance) => {
    const {
        event,
        isSwiper,
        setPageType
    } = instance.props;

    if (!isSwiper) {
        setPageType(PAGE_TYPE_CATEGORY);
        event(EVENT_GTM_DYNAMIC_RETARGETING, PAGE_TYPE_CATEGORY);
    }
};

const renderPage = (args, callback, instance) => {
    const [props = {}] = args;
    const {
        isInfiniteLoaderEnabled,
        loadPage,
        isLoading,
        isVisible,
        mix,
        event,
        isSwiper
    } = instance.props;
    const {
        items,
        keys,
        pageNumber,
        selectedFilters,
        wrapperRef,
        key
    } = instance._processProps(props);

    const newProps = instance._processProps(props);
    return (
        <ProductListPage
          event={ event }
          isSwiper={ isSwiper }
          key={ key }
          isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
          updatePages={ loadPage }
          isLoading={ isLoading }
          isVisible={ isVisible }
          mix={ mix }
          items={ items }
          keys={ keys }
          pageNumber={ pageNumber }
          selectedFilters={ selectedFilters }
          wrapperRef={ wrapperRef }
          // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
          { ...newProps }
        />
    );
};

const containerProps = (args, callback, instance) => {
    const {
        event,
        setPageType
    } = instance.props;

    return {
        ...callback(...args),
        setPageType,
        event
    };
};

export default {
    'Bodypwa/Component/ProductList/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Bodypwa/Component/ProductList/Container': {
        'member-function': {
            containerProps
        }
    },
    'Bodypwa/Component/ProductList/Component': {
        'member-function': {
            renderPage,
            componentDidMount
        }
    }
};
