import { Field } from 'Util/Query';

const getSeoParams = () => [
    'param',
    'value'
];

const paramField = () => new Field('params')
    .addFieldList(getSeoParams());

const getQuery = (args, callback, instance) => callback(...args)
    .addField(paramField());

export default {
    'Query/UrlRewrites': {
        'member-function': {
            getQuery
        }
    }
};
