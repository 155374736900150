/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { addBlockReferenceAfter } from '../../component/LayoutUpdate';
import { BR_MINI_CART_PROMOTION_BLOCK } from '../../util/blocks';

export default {
    'Component/CartOverlay/Component': {
        'member-function': {
            renderPromo: addBlockReferenceAfter(BR_MINI_CART_PROMOTION_BLOCK)
        }
    }
};
