import CmsBlock from 'Component/CmsBlock';

const renderBlock = (instance) => {
    const {
        category: { id, top_block },
        isCurrentCategoryLoaded
    } = instance.props;

    if (!id || !isCurrentCategoryLoaded) {
        return instance.renderCategoryDescriptionPlaceholder();
    }

    if (!top_block) {
        return null;
    }

    return (
        <div block="CategoryPage" elem="ItemsCount">
            <CmsBlock identifier={ top_block } />
        </div>
    );
};

const render = (args, callback, instance) => (
    <>
        { callback(...args) }
        { renderBlock(instance) }
    </>
);

export default {
    'Component/CategoryDetails/Component': {
        'member-function': {
            render
        }
    }
};
