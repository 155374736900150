import CheckoutSuccess from 'Component/CheckoutSuccess';

import { event, setPageType } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        setPageType: (pageType) => dispatch(setPageType(pageType))
    };
};

const renderDetailsStep = (args, callback, instance) => {
    const {
        orderID,
        isEmailAvailable,
        email,
        event,
        setPageType,
        billingAddress: {
            firstname,
            lastname
        }
    } = instance.props;

    return (
        <CheckoutSuccess
          email={ email }
          event={ event }
          setPageType={ setPageType }
          firstName={ firstname }
          lastName={ lastname }
          isEmailAvailable={ isEmailAvailable }
          orderID={ orderID }
        />
    );
};

export default {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep
        }
    }
};
