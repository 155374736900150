/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import { PaymentMethodsReducer } from '../store/PaymentMethods/PaymentMethods.reducer';
import { PayPalPaymentReducer } from '../store/PayPalPayment/PayPalPayment.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    PayPalPaymentReducer,
    PaymentMethodsReducer
});

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
