import { UPDATE_CURRENT_CATEGORY } from 'Store/Category/Category.action';
import { getInitialState } from 'Store/Category/Category.reducer';

const CategoryReducer = (args, callback, instance) => {
    const [state = getInitialState(), action] = args;
    const originalUpdatedState = callback.apply(instance, args);

    if (!action) {
        return originalUpdatedState;
    }

    const {
        category: prevCat, originalCategory = {},
        category: {
            requestOriginal: original = false,
            id: originalID, seo: prevSeo = false
        } = {}
    } = state;

    const { type, category, category: { seo = false, requestOriginal, id } = {} } = action;

    if (original === false && requestOriginal === undefined
        && (category !== undefined || id === originalID)
        && prevSeo === true) {
        return {
            ...state,
            category: { ...category, ...prevCat }
        };
    }

    if (requestOriginal) {
        switch (type) {
        case UPDATE_CURRENT_CATEGORY:
            return {
                ...state,
                category: originalCategory
            };
        default:
            return state;
        }
    }

    if (seo) {
        switch (type) {
        case UPDATE_CURRENT_CATEGORY:
            return {
                ...state,
                category: { ...prevCat, ...category }
            };

        default:
            return state;
        }
    }

    switch (type) {
    case UPDATE_CURRENT_CATEGORY:
        return {
            ...state,
            category,
            originalCategory: { category }
        };

    default:
        return state;
    }
};

export default {
    'Store/Category/Reducer': {
        function: CategoryReducer
    }
};
