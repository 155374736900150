import { isMobile } from 'Util/Mobile';

const handleMobileRouteChange = (args, callback, instance) => {
    const { headerState: { name } } = instance.props;

    if (name !== 'filter' && isMobile.any()) {
        callback(...args);
    }
};

export default {
    'Component/NavigationAbstract/Container': {
        'member-function': {
            handleMobileRouteChange
        }
    }
};
