/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { addBlockReferenceBefore } from '../../component/LayoutUpdate';
import { BR_CHECKOUT_SUCCES } from '../../util/blocks';

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            renderButtons: addBlockReferenceBefore(BR_CHECKOUT_SUCCES)
        }
    }
};
