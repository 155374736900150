/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazyComponentLoader } from 'Util/lazyComponentLoader';

export const GdprCookieGroupPopup = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "gdpr" */
        '../component/GdprCookieGroupPopup'
    ), 2
);

export const GdprCookiePopup = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "gdpr" */
        '../component/GdprCookiePopup'
    ), 2
);

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const AFTER_ITEMS_TYPE = (originalMember) => ([
    ...originalMember,
    {
        component: <GdprCookiePopup isPopUp />,
        position: 26
    },
    {
        component: <GdprCookieGroupPopup isPopUp />,
        position: 30
    }
]);

export default {
    'Component/Router/Component': {
        'member-property': {
            AFTER_ITEMS_TYPE
        }
    }
};
