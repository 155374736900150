/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import history from 'Util/History';

export class MenuPlugin {
    onRouteChanged(args, callback, instance) {
        const { activeOverlay, isSideMenuOpen } = instance.props;
        if (isSideMenuOpen) {
            if (activeOverlay !== CATEGORY_FILTER_OVERLAY_ID && isSideMenuOpen) {
                instance.props.closeSideMenu();
            }
        }
    }

    componentDidMount(args, callback, instance) {
        history.listen((history) => {
            instance.setState(instance.onRouteChanged(history));
        });
    }
}

const { onRouteChanged, componentDidMount } = new MenuPlugin();

export default {
    'Component/Menu/Component': {
        'member-function': {
            componentDidMount,
            onRouteChanged
        }
    }
};
