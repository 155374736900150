import { PAGE_TYPE_CHECKOUT } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    EVENT_GTM_CHECKOUT,
    EVENT_GTM_DYNAMIC_RETARGETING
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event, setPageType } from '../store/GoogleTagManager/GoogleTagManager.action';

const onShippingSuccess = (args, callback, instance) => {
    callback.apply(instance, args);
};

const componentDidMount = (args, callback, instance) => {
    const { event } = instance.props;
    setPageType(PAGE_TYPE_CHECKOUT);
    event(EVENT_GTM_DYNAMIC_RETARGETING, PAGE_TYPE_CHECKOUT);

    callback.apply(instance, args);
};

const componentDidUpdate = (args, callback, instance) => {
    const { event, cartData: { items = [] } = [] } = instance.props;
    const { isEventSent } = instance.state;

    if (items.length && !isEventSent) {
        instance.setState({ isEventSent: true });
        event(EVENT_GTM_CHECKOUT, { step: 1 });
    }
    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        setPageType: (pageType) => dispatch(setPageType(pageType))
    };
};

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess,
            componentDidMount,
            componentDidUpdate
        }
    },
    'Component/CheckoutShipping/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
