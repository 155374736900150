import GdprCookieGroupPopup from '../component/GdprCookieGroupPopup';
import GdprCookiePopup from '../component/GdprCookiePopup';

const render = () => (
    <div block="GdprCookiePage">
        <GdprCookiePopup />
        <GdprCookieGroupPopup />
    </div>
);

export default {
    'Route/GdprCookiePage/GdprCookiePage': {
        'member-function': {
            render
        }
    }
};
