/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const LayoutUpdateType = PropTypes.shape({
    page_id: PropTypes.string,
    instance_id: PropTypes.string,
    page_group: PropTypes.string,
    layout_handle: PropTypes.string,
    block_reference: PropTypes.string,
    page_for: PropTypes.string,
    entities: PropTypes.string,
    page_template: PropTypes.string
});
