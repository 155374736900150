/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import { Suspense } from 'react';

import Loader from 'Component/Loader';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';

import { PAYPAL_EXPRESS, PAYPAL_EXPRESS_CREDIT } from '../component/PayPal/PayPal.config';

const selectPaymentMethod = (args, callback, instance) => {
    instance.setState({ selectedMethodType: args[0].type || '' });
    callback(...args);
};

export const PayPal = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout" */ '../component/PayPal'
), 2);

export const renderPayPal = (instance) => {
    const {
        selectedPaymentCode,
        setLoading,
        setDetailsStep,
        widgetLocation,
        setOrderButtonVisibility,
        checkoutFormRef
    } = instance.props;

    return (
        <Suspense fallback={ <Loader /> }>
            <PayPal
              setLoading={ setLoading }
              setOrderButtonVisibility={ setOrderButtonVisibility }
              widgetLocation={ widgetLocation } // TODO: check where we can get this value
              setDetailsStep={ setDetailsStep }
              selectedPaymentCode={ selectedPaymentCode }
              checkoutFormRef={ checkoutFormRef }
            />
        </Suspense>
    );
};

export const paymentRenderMap = (originalMember, instance) => ({
    ...originalMember,
    [PAYPAL_EXPRESS_CREDIT]: instance.renderNotSupported.bind(instance),
    [PAYPAL_EXPRESS]: () => renderPayPal(instance)
});

export const componentDidUpdate = (args, callback = () => {}, instance) => {
    const [prevProps] = args;
    const { selectedPaymentCode, setOrderButtonVisibility } = instance.props;
    const { selectedPaymentCode: prevSelectedPaymentCode } = prevProps;

    if (selectedPaymentCode !== prevSelectedPaymentCode) {
        if (selectedPaymentCode === PAYPAL_EXPRESS) {
            setOrderButtonVisibility(false);
        }

        if (prevSelectedPaymentCode === PAYPAL_EXPRESS) {
            setOrderButtonVisibility(true);
        }
    }

    callback.apply(instance, args);
};

export const componentDidMount = (args, callback, instance) => {
    const { selectedPaymentCode, setOrderButtonVisibility } = instance.props;

    callback(...args);

    if (selectedPaymentCode === PAYPAL_EXPRESS) {
        setOrderButtonVisibility(false);
    }
};

const containerProps = (args, callback, instance) => {
    const { selectedMethodType } = instance.state;
    const { setOrderButtonVisibility } = instance.props;

    return {
        ...callback(...args),
        selectedMethodType,
        setOrderButtonVisibility
    };
};

export const config = {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            componentDidUpdate,
            componentDidMount
        }
    },
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap
        }
    },
    'Component/CheckoutPayments/Container': {
        'member-function': {
            selectPaymentMethod,
            containerProps
        }
    }
};

export default config;
