/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

/* eslint-disable import/prefer-default-export */
export * from '@scandipwa/scandipwa/src/route/Checkout/Checkout.config';
export const INSTANT_PAYPAL_BILLING_STEP = 'INSTANT_PAYPAL_BILLING_STEP';
