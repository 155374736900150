const componentDidUpdate = (args, callback, instance) => {
    const [prevProps, _, __] = args;
    const { identifier: prevId = '' } = prevProps;
    const {
        identifier = ''
    } = instance.props;

    if (prevId !== identifier) {
        instance._getCmsBlock();
    }
};

export default {
    'Component/CmsBlock/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
