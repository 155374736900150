import { PAGE_TYPE_PDP } from '../component/GoogleTagManager/GoogleTagManager.config';
import { EVENT_GTM_DYNAMIC_RETARGETING, EVENT_GTM_PRODUCT_DETAIL }
from '../component/GoogleTagManager/GoogleTagManager.events';
import { event, setPageType } from '../store/GoogleTagManager/GoogleTagManager.action';

const componentDidMount = (args, callback, instance) => {
    const { event, setPageType } = instance.props;
    setPageType(PAGE_TYPE_PDP);
    event(EVENT_GTM_DYNAMIC_RETARGETING, PAGE_TYPE_PDP);
    return callback.apply(instance, args);
};

const componentDidUpdate = (args, callback, instance) => {
    const { event, product = {} } = instance.props;
    const { fired = false } = instance.state;
    if (!(Object.keys(product).length === 0) && !fired) {
        instance.setState({ fired: true });
        event(EVENT_GTM_PRODUCT_DETAIL, { ...product });
    }

    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        setPageType: (pageType) => dispatch(setPageType(pageType))
    };
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate,
            componentDidMount
        }
    },
    'Route/ProductPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
