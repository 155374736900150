import { Field } from 'Util/Query';

const _getAggregationsFields = (args, callback, instance) => [
    ...callback(...args),
    'isSeo'
];

const _getShopbySeoFields = () => [
    'title',
    'description',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'top_block',
    'bottom_block',
    'image',
    'canonical_url'
];

const _getShopbySeoField = () => new Field('shopbyPage')
    .addFieldList(_getShopbySeoFields());

const _getProductFields = (args, callback, instance) => {
    const original = callback(...args);
    const { requireInfo } = instance.options;

    if (requireInfo) {
        return [
            ...callback(...args),
            _getShopbySeoField()
        ];
    }

    return original;
};

export default {
    'Query/ProductList': {
        'member-function': {
            _getAggregationsFields,
            _getProductFields
        }
    }
};
