/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { addBlockReferenceAfter, addBlockReferenceBefore } from '../../component/LayoutUpdate';
import {
    BR_AFTER_PAGE_HEADER, BR_BEFORE_MAIN_COLUMNS, BR_PAGE_BOTTOM, BR_PAGE_TOP
} from '../../util/blocks';

export const BEFORE_ITEMS_TYPE = (originalMember) => {
    if (originalMember.find(({ name }) => name === BR_AFTER_PAGE_HEADER)) {
        return originalMember;
    }

    return [
        ...originalMember,
        {
            component: addBlockReferenceBefore(BR_AFTER_PAGE_HEADER),
            widget: true,
            name: BR_AFTER_PAGE_HEADER,
            position: 9000
        }
    ];
};
export default {
    'Component/Router/Component': {
        'member-function': {
            renderRouterContent: [
                addBlockReferenceBefore(BR_PAGE_TOP),
                addBlockReferenceAfter(BR_BEFORE_MAIN_COLUMNS),
                addBlockReferenceAfter(BR_PAGE_BOTTOM)
            ],
            BEFORE_ITEMS_TYPE
        }
    }
};
