/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    addBlockReferenceAfter,
    addBlockReferenceAfterWrapper,
    addBlockReferenceBefore
} from '../../component/LayoutUpdate';
import {
    // BR_AFTER_PAGE_HEADER,
    BR_AFTER_PAGE_HEADER_TOP,
    BR_PAGE_HEADER,
    BR_PAGE_HEADER_CONTAINER,
    BR_PAGE_HEADER_PANEL
} from '../../util/blocks';

export default {
    'Component/Header/Component': {
        'member-function': {
            // render: addBlockReferenceAfter(BR_AFTER_PAGE_HEADER),
            renderTopMenu: [
                addBlockReferenceBefore(BR_PAGE_HEADER_CONTAINER),
                addBlockReferenceAfterWrapper(BR_PAGE_HEADER_PANEL),
                addBlockReferenceAfter(BR_PAGE_HEADER)
            ]
        }
    },
    'Component/DemoNotice/Component': {
        'member-function': {
            render: addBlockReferenceAfterWrapper(BR_AFTER_PAGE_HEADER_TOP)
        }
    }
};
