import CheckoutQuery from 'Query/Checkout.query';
import {
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId } from 'Util/Cart';
import {
    fetchMutation,
    fetchQuery
} from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { getQueryParam } from 'Util/Url';

import { ADYEN_CC, THREE_D } from '../component/AdyenCC/AdyenCC.config';
import { ADYEN_HPP } from '../component/AdyenHPP/AdyenHPP.config';
import AdyenQuery from '../query/Adyen.query';

const saveAddressInformation = async (args, callback, instance) => {
    const { setIsCheckoutLoading } = instance.props;
    const [addressInformation] = args;
    const { updateShippingPrice } = instance.props;
    const { shipping_address, shipping_method_code } = addressInformation;
    const { shippingMethods } = instance.state;
    const isShippingMethodAvailable = shippingMethods?.some(
        ({ method_code }) => method_code === shipping_method_code
    );

    if (!shipping_method_code || !isShippingMethodAvailable) {
        instance.setState({ isLoading: false });
        return;
    }
    setIsCheckoutLoading(true);

    instance.setState({
        isLoading: true,
        shippingAddress: shipping_address,
        selectedShippingMethod: shipping_method_code
    });

    if (!isSignedIn()) {
        await instance.createUserOrSaveGuest();
    }
    await fetchMutation(CheckoutQuery.getSaveAddressInformation(
        instance.prepareAddressInformation(addressInformation),
        getGuestQuoteId()
    )).then(
        /** @namespace Bodypwa/Route/Checkout/Container/CheckoutContainer/saveAddressInformation/fetchMutation/then */
        ({ saveAddressInformation: data }) => {
            const { payment_methods, totals } = data;

            fetchQuery(AdyenQuery.getPaymentMethods()).then(
                ({ getAdyenPaymentMethods }) => {
                    const adyenPaymentMethods = JSON.parse(getAdyenPaymentMethods);
                    instance.setState({ adyenPaymentMethods });
                    setIsCheckoutLoading(false);
                    instance.setState({
                        isLoading: false,
                        paymentMethods: payment_methods,
                        paymentTotals: totals
                    });
                    updateShippingPrice(totals);

                    BrowserDatabase.setItem(
                        totals,
                        PAYMENT_TOTALS,
                        ONE_MONTH_IN_SECONDS
                    );
                },
                instance._handleError
            );
        },
        instance._handleError
    );
};

const getOrderFromUrl = (instance) => {
    const orderId = getQueryParam('id', instance.props.location);

    if (!orderId) {
        return false;
    }

    return orderId.replace('/', '');
};

const componentDidMount = (args, callback, instance) => {
    const orderId = getOrderFromUrl(instance);

    if (orderId) {
        instance.setDetailsStep(orderId);
        return null;
    }

    return callback(...args);
};

const containerProps = (args, callback, instance) => {
    const { adyenPaymentMethods = null } = instance.state;

    return {
        ...callback(...args),
        adyenPaymentMethods
    };
};

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;
    const { showThreePopUp } = instance.props;
    const {
        paymentMethod: {
            code, additional_data, purchase_order_number
        }
    } = paymentInformation;
    const isCustomerSignedIn = isSignedIn();
    const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

    if (code === ADYEN_CC || code === ADYEN_HPP) {
        if (!isCustomerSignedIn && !guest_cart_id) {
            return;
        }
        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    [code]: additional_data,
                    purchase_order_number
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));

            const { placeOrder: { order: { order_id }, action = false, id = 0 } } = orderData;

            instance.setState({ order_id: id, order_increment: order_id });

            if (action) {
                showThreePopUp({ title: __('Secure') });
                window.adyen.createFromAction(JSON.parse(action), { challengeWindowSize: '05' }).mount('#three');
                instance.setState({ isLoading: false });
            } else {
                instance.setDetailsStep(order_id);
            }
        } catch (e) {
            instance._handleError(e);
        }
    } else {
        callback(...args);
    }
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        showThreePopUp: (payload) => dispatch(showPopup(THREE_D, payload)),
        hideActiveOverlay: () => dispatch(hideActiveOverlay())
    };
};

export class CheckoutContainerPlugin {
    handleDetails(data) {
        const { order_id, order_increment } = this.state;
        const { hideActiveOverlay } = this.props;
        hideActiveOverlay();
        this.setState({ isLoading: true });

        const requstData = {
            ...data,
            orderId: order_id
        };
        const payload = JSON.stringify(requstData);

        fetchQuery(AdyenQuery.handleAdyenPaymentDetails(payload)).then(({ adyenPaymentDetails }) => {
            const result = JSON.parse(adyenPaymentDetails);
            const { resultCode } = result;

            if (resultCode === 'Authorised') {
                this.setDetailsStep(order_increment);
            } else {
                this._handleError();
            }
        });
    }

    containerFunctions = (members, instance) => ({
        ...members,
        handleDetails: this.handleDetails.bind(instance)
    });
}

const { containerFunctions } = new CheckoutContainerPlugin();

export default {
    'Route/Checkout/Container': {
        'member-property': {
            containerFunctions
        },
        'member-function': {
            saveAddressInformation,
            containerProps,
            savePaymentMethodAndPlaceOrder,
            componentDidMount
        }
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
