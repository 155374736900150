const getWishlistProduct = (args, callback, instance) => {
    const { product: { categories = [] } } = instance.props;
    const result = callback(...args);

    return { ...result, categories };
};

export default {
    'Component/WishlistItem/Component': {
        'member-function': {
            getWishlistProduct
        }
    }
};
