import { updateCurrentCategory } from 'Store/Category/Category.action';

const onSuccess = (args, callback, instance) => {
    const [{ products: { shopbyPage = false } }, dispatch, _] = args;

    callback(...args);

    if (shopbyPage) {
        const {
            meta_title, meta_description, meta_keywords, top_block, description, image, canonical_url
        } = shopbyPage;

        const data = {
            name: shopbyPage.title,
            seo: true,
            requestOriginal: false,
            meta_title,
            meta_description,
            meta_keywords,
            top_block,
            description,
            image,
            canonical_url
        };

        dispatch(updateCurrentCategory(data));
    } else {
        // TODO whenwe will need this feature, investigate what is the scope of this
        // dispatch(updateCurrentCategory({ requestOriginal: true, seo: false }));
    }
};

export default {
    'Store/ProductListInfo/Dispatcher': {
        'member-function': {
            onSuccess
        }
    }
};
