const getPlaceOrderMutation = (args, callback, instance) => {
    const mutation = callback(...args);

    mutation
        .addFieldList(['id', 'action']);

    return mutation;
};

export default {
    'Query/Checkout/Query': {
        'member-function': {
            getPlaceOrderMutation
        }
    }
};
