import { isMobile } from 'Util/Mobile';

const ADD = 'add';
const CHANGE = 'change';
const REMOVE = 'remove';

// Check if filter exists
const filterExist = (key, filters) => typeof filters[key] !== 'undefined';

const toggleCustomFilter = (args, callback, instance) => {
    const [requestVar, value] = args;
    const { availableFilters } = instance.props;

    if (filterExist(requestVar, availableFilters) && availableFilters[requestVar].isSeo) {
        const { isSeo } = availableFilters[requestVar];
        instance.updateFilter(requestVar, instance._getNewFilterArray(requestVar, value), isSeo);
    } else {
        instance.updateFilter(requestVar, instance._getNewFilterArray(requestVar, value), false);
    }
};

const removeSeoPart = (filterName, location, suffix, filter_key) => {
    const pathname = location.pathname.replace(suffix, '');
    const { search = '' } = location;
    const seoPart = pathname.split('/').pop();
    const pathWithoutSeoPart = pathname.replace(`${filter_key}/${seoPart}`, '');
    const seoPartArray = seoPart.split('-');
    const filterIndex = seoPartArray.indexOf(filterName);
    const newSeoPartArray = [];

    seoPartArray.map((part, index) => {
        if (index === filterIndex || index === filterIndex + 1) {
        } else {
            newSeoPartArray.push(part);
        }
    });

    if (newSeoPartArray.length) {
        const newSeoPart = newSeoPartArray.join('-');
        return `${pathWithoutSeoPart}${filter_key}/${newSeoPart}${suffix}${search}`;
    }

    return `${pathWithoutSeoPart}${suffix}${search}`;
};

const getSeoUrl = (filterName, filterValue, seoFilterParams, location, suffix, filter_key) => {
    const pathname = location.pathname.replace(suffix, '');
    const { search = '' } = location;

    if (seoFilterParams) {
        return `${pathname}-${filterName}-${filterValue}${suffix}${search}`;
    }

    return `${pathname}${filter_key}/${filterName}-${filterValue}${suffix}${search}`;
};

const changeSeoPart = (filterName, value, seoFilterParams, location, suffix, filter_key = '') => {
    const pathname = location.pathname.replace(suffix, '');
    const { search = '' } = location;
    const seoPart = pathname.split('/').pop();
    const pathWithoutSeoPart = pathname.replace(`${filter_key}/${seoPart}`, '');
    const seoPartArray = seoPart.split('-');
    const filterIndex = seoPartArray.indexOf(filterName);
    const newSeoPartArray = [];

    seoPartArray.map((part, index) => {
        if (index === filterIndex + 1) {
            newSeoPartArray.push(value);
        } else {
            newSeoPartArray.push(part);
        }
    });

    const newSeoPart = newSeoPartArray.join('-');

    return `${pathWithoutSeoPart}${filter_key}/${newSeoPart}${suffix}${search}`;
};

const getFilterArrayNormal = (filterArray) => {
    const data = [];
    data.push(filterArray[filterArray.length - 1]);
    const stringLen = data[0].length;
    const secondValue = filterArray.join('').slice(0, -stringLen);
    data.push(secondValue);

    return data;
};

const getFilterAction = (filterArray = []) => {
    if (filterArray.length === 1) {
        return ADD;
    }
    if (filterArray.length > 1) {
        const filterArrayNormal = getFilterArrayNormal(filterArray);

        if (filterArrayNormal[0] === filterArrayNormal[1]) {
            return REMOVE;
        }

        return CHANGE;
    }

    return '';
};

const updateFilter = (args, callback, instance) => {
    const [filterName, filterArray, isSeo] = args;
    const {
        location, history, availableFilters, suffix, seoFilterParams, filter_key
    } = instance.props;

    if (!isSeo) {
        callback(...args);
    } else {
        const action = getFilterAction(filterArray);

        if (filterExist(filterName, availableFilters)) {
            const { attribute_options } = availableFilters[filterName];
            switch (action) {
            case ADD:
                const filterValue = attribute_options[filterArray[0]].label.toLowerCase();
                const seoUrl = getSeoUrl(filterName, filterValue, seoFilterParams, location, suffix, filter_key);
                history.push(seoUrl);
                break;
            case REMOVE:
                const removeSeoUrl = removeSeoPart(filterName, location, suffix, filter_key);
                history.push(removeSeoUrl);
                break;
            case CHANGE:
                const value = attribute_options[filterArray[filterArray.length - 1]].label.toLowerCase();
                const changeSeoUrl = changeSeoPart(filterName, value, seoFilterParams, location, suffix, filter_key);
                history.push(changeSeoUrl);
                break;
            default:
                console.error(__('Something went Wrong'));
            }
        }
    }
};

const getFilterUrl = (args, callback, instance) => {
    const [filterName, filterArray, isFull = true] = args;
    const {
        location, history, availableFilters, suffix, seoFilterParams, filter_key
    } = instance.props;

    const action = getFilterAction(filterArray);

    if (filterExist(filterName, availableFilters) && availableFilters[filterName].isSeo) {
        const { attribute_options } = availableFilters[filterName];
        switch (action) {
        case ADD:
            const filterValue = attribute_options[filterArray[0]].label.toLowerCase();
            const seoUrl = getSeoUrl(filterName, filterValue, seoFilterParams, location, suffix, filter_key);
            return seoUrl;

            break;
        case REMOVE:
            const removeSeoUrl = removeSeoPart(filterName, location, suffix, filter_key);
            return removeSeoUrl;

            break;
        case CHANGE:
            const value = attribute_options[filterArray[filterArray.length - 1]].label.toLowerCase();
            const changeSeoUrl = changeSeoPart(filterName, value, seoFilterParams, location, suffix, filter_key);
            return changeSeoUrl;

            break;
        default:
            console.error(__('Something went Wrong'));
        }
    }

    return callback(...args);
};

const getFilterKey = (key) => {
    if (key !== undefined) {
        return `/${key}`;
    }

    return '';
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        suffix: state.ConfigReducer.category_url_suffix || '.html',
        seoFilterParams: state.UrlRewritesReducer.urlRewrite.params,
        filter_key: getFilterKey(state.ConfigReducer.filter_key)
    };
};

const historyBackHook = (args, callback, instance) => {
    if (!isMobile.any()) {
        callback(...args);
    }
};

export default {
    'Component/CategoryFilterOverlay/Container': {
        'member-function': {
            toggleCustomFilter,
            updateFilter,
            historyBackHook,
            getFilterUrl
        }
    },
    'Component/CategoryFilterOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
