/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';

import PrivacyConsentCheckboxes from '../component/PrivacyConsentCheckboxes';
import { AREA_REGISTRATION, formatConsents } from '../util/Privacy';

export const renderSubmitButton = (args, callback, instance) => {
    const original = callback.apply(instance, args);

    const { updatePrivacyConsentSelection } = instance.props;

    return (
        <PrivacyConsentCheckboxes
          key="gdpr-consent-checkboxes"
          area={ AREA_REGISTRATION }
          updateRegistrationSelection={ updatePrivacyConsentSelection }
        >
            { original }
        </PrivacyConsentCheckboxes>
    );
};

/**
 * Completely replaces (copied) original implementation - need to add value privacyConsentSelection to customerData
 * @param args
 * @param callback
 * @param instance
 */
export const onCreateAccountSuccess = (args, callback, instance) => {
    const [fields] = args;

    const {
        privacyConsentSelection
    } = instance.state;

    const formattedConsentSelection = formatConsents(privacyConsentSelection);

    const {
        createAccount,
        onSignIn,
        setSignInState,
        setLoadingState
    } = instance.props;

    const {
        password,
        email,
        firstname,
        lastname,
        is_subscribed
    } = fields;

    const customerData = {
        customer: {
            firstname,
            lastname,
            email,
            is_subscribed,
            privacyConsentSelection: formattedConsentSelection
        },
        password
    };

    createAccount(customerData)
        .then(
            (code) => {
                // if user needs confirmation
                if (code === 2) {
                    setSignInState(STATE_CONFIRM_EMAIL);
                } else {
                    onSignIn();
                }

                setLoadingState(false);
            },
            () => {
                setLoadingState(false);
            }
        );
};

export const render = (args, callback, instance) => {
    const updatePrivacyConsentSelection = (selection) => {
        instance.setState({
            privacyConsentSelection: selection
        });
    };

    const original = callback.apply(instance, args);
    // eslint-disable-next-line no-undef
    return React.cloneElement(
        original,
        { updatePrivacyConsentSelection },
        original.props.children
    );
};

export default {
    'Component/MyAccountCreateAccount/Component': {
        'member-function': {
            renderSubmitButton
        }
    },
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            onCreateAccountSuccess,
            render
        }
    }
};
