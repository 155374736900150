import { PAGE_TYPE_HOME } from '../component/GoogleTagManager/GoogleTagManager.config';
import { EVENT_GTM_DYNAMIC_RETARGETING }
from '../component/GoogleTagManager/GoogleTagManager.events';
import { event, setPageType } from '../store/GoogleTagManager/GoogleTagManager.action';

const componentDidMount = (args, callback, instance) => {
    const { event, setPageType } = instance.props;
    setPageType(PAGE_TYPE_HOME);
    event(EVENT_GTM_DYNAMIC_RETARGETING, PAGE_TYPE_HOME);
    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        setPageType: (pageType) => dispatch(setPageType(pageType))
    };
};

export default {
    'Route/HomePage/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'Route/HomePage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
