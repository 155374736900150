import { EVENT_GTM_SITE_SEARCH_STARTED } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const state = (original) => ({
    ...original,
    isAssigned: false
});

const onSearchFieldClick = (event) => {
    event(EVENT_GTM_SITE_SEARCH_STARTED, {});
};

const renderSearch = (args, callback, instance) => {
    const { current = false } = instance.searchBarRef;
    const { isAssigned = false } = instance.state;
    const { event } = instance.props;

    if (current && !isAssigned) {
        instance.searchBarRef.current
            .addEventListener('focus', () => onSearchFieldClick(event));
        instance.setState({ isAssigned: true });
    }

    return callback(...args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Component/SearchField/Component': {
        'member-function': {
            renderSearch
        },
        'member-property': {
            state
        }
    },
    'Component/SearchField/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
