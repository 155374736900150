/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

const _getStoreConfigFields = (args, callback) => [
    ...callback(...args),
    'paypal_sandbox_flag',
    'paypal_client_id'
];

export const config = {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};

export default config;
