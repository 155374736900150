import { cloneElement, Suspense } from 'react';

import Loader from 'Component/Loader';

const renderBillingStep = (args, callback, instance) => {
    const { adyenPaymentMethods, handleDetails } = instance.props;
    const originalElement = callback(...args);

    return (
        <Suspense fallback={ <Loader /> }>
            { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
            { originalElement.props.children
                ? cloneElement(originalElement.props.children, { adyenPaymentMethods, handleDetails }) : '' }
        </Suspense>
    );
};

export default {
    'Route/Checkout/Component': {
        'member-function': {
            renderBillingStep
        }
    }
};
