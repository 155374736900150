/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { addBlockReferenceBefore, addBlockReferenceBeforeWrapper } from '../../component/LayoutUpdate';
import {
    BR_BEFORE_PAGE_FOOTER,
    BR_BEFORE_PAGE_FOOTER_CONTAINER,
    BR_CMS_FOOTER_LINKS,
    BR_PAGE_FOOTER,
    BR_PAGE_FOOTER_CONTAINER
} from '../../util/blocks';

export default {
    'Component/Footer/Component': {
        'member-function': {
            render: [
                addBlockReferenceBeforeWrapper(BR_PAGE_FOOTER_CONTAINER),
                addBlockReferenceBefore(BR_BEFORE_PAGE_FOOTER_CONTAINER)
            ],
            renderContent: [
                addBlockReferenceBeforeWrapper(BR_PAGE_FOOTER),
                addBlockReferenceBefore(BR_BEFORE_PAGE_FOOTER)
            ],
            renderColumns: addBlockReferenceBeforeWrapper(BR_CMS_FOOTER_LINKS)
        }
    }
};
