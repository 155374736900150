import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import { isMobile } from 'Util/Mobile';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoFilterParams: state.UrlRewritesReducer.urlRewrite.params,
        activeOverlay: state.OverlayReducer.activeOverlay
    };
};

const getSelectedFiltersFromUrl = (args, callback, instance) => {
    const { seoFilterParams } = instance.props;

    const urlFilters = callback(...args);

    if (seoFilterParams && seoFilterParams.length) {
        const rewriteFilters = seoFilterParams.reduce((acc, params) => {
            if (!params) {
                return acc;
            }

            const { param, value } = params;

            return { ...acc, [param]: value };
        }, {});

        return { ...urlFilters, ...rewriteFilters };
    }

    return urlFilters;
};

const componentDidUpdate = (args, callback, instance) => {
    const prevProps = args[0];
    const {
        category: { name = '' }
    } = instance.props;

    callback(...args);

    const { category: { name: prevName = '' }, activeOverlay: prevOverlay } = prevProps;

    if (name !== prevName) {
        instance.updateMeta();
        instance.updateBreadcrumbs();
        instance.updateHeaderState();
    }

    if (prevOverlay === CATEGORY_FILTER_OVERLAY_ID && isMobile.any()) {
        instance.updateHeaderState();
    }
};

const updateHeaderState = (args, callback, instance) => {
    const { activeOverlay } = instance.props;

    if (activeOverlay !== CATEGORY_FILTER_OVERLAY_ID) {
        callback(...args);
    }
};

const updateNavigationState = (args, callback, instance) => {
    const { activeOverlay } = instance.props;

    if (activeOverlay !== CATEGORY_FILTER_OVERLAY_ID && !isMobile.any()) {
        callback(...args);
    }
};

export default {
    'Route/CategoryPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            getSelectedFiltersFromUrl,
            componentDidUpdate,
            updateHeaderState,
            updateNavigationState
        }
    }
};
