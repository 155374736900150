import { ADYEN_CC } from '../component/AdyenCC/AdyenCC.config';
import { ADYEN_HPP } from '../component/AdyenHPP/AdyenHPP.config';

const selectPaymentMethod = (args, callback, instance) => {
    const { setOrderButtonEnableStatus } = instance.props;
    instance.setState({ selectedMethodType: args[0].type || '' });
    callback(...args);

    setOrderButtonEnableStatus(true);
};

const containerProps = (args, callback, instance) => {
    const { selectedMethodType } = instance.state;
    const { setOrderButtonVisibility } = instance.props;

    return {
        ...callback(...args),
        selectedMethodType,
        setOrderButtonVisibility
    };
};

// TODO check how to use this logic
export function getAdyenCCData() {
    const { billingAddress: { guest_email }, email = '' } = this.props;
    const result_email = email || guest_email;

    return {
        asyncData: {
            additional_data: {
                guestEmail: result_email,
                combo_card_type: 'credit',
                is_active_payment_token_enabler: false,
                number_of_installments: ''
            }
        }
    };
}

// TODO check how to use this logic
export function getAdyenHPPData() {
    const { selectedMethodType } = this.state;
    const { billingAddress: { guest_email }, email = '' } = this.props;
    const result_email = email || guest_email;

    return {
        asyncData: {
            additional_data: {
                guestEmail: result_email,
                brand_code: selectedMethodType
            }
        }
    };
}

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/dataMap */
export const dataMap = (originalMember, instance) => ({
    ...originalMember,
    [ADYEN_CC]: getAdyenCCData.bind(instance),
    [ADYEN_HPP]: getAdyenHPPData.bind(instance)
});

export default {
    'Component/CheckoutPayments/Container': {
        'member-function': {
            selectPaymentMethod,
            containerProps
        },
        'member-property': {
            dataMap
        }
    }
};
