/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';
import React from 'react';

import InstantPayment from '../component/InstantPayment';
import { INSTANT_PAYMENT_LOCATION_CART } from '../component/InstantPayment/InstantPayment.config';

const renderContent = (args, callback, instance) => (
    <ContentWrapper
      wrapperMix={ { block: 'CartPage', elem: 'Wrapper' } }
      label="Cart page details"
    >
        <div block="CartPage" elem="Static">
                    { instance.renderHeading() }
                    { instance.renderCartItems() }
                    { instance.renderDiscountCode() }
                    { instance.renderCrossSellProducts() }
        </div>
        <div block="CartPage" elem="Floating">
            { instance.renderPromo() }
            { instance.renderTotals() }
            <InstantPayment
              key={ INSTANT_PAYMENT_LOCATION_CART }
              widgetLocation={ INSTANT_PAYMENT_LOCATION_CART }
            />
        </div>
    </ContentWrapper>
);
const render = (args, callback, instance) => {
    const {
        totals: {
            items_qty
        } = {}
    } = instance.props;

    const isContentLoading = items_qty === -1;
    const content = isContentLoading ? instance.renderCartLoading() : instance.renderContent();
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    const loadingHeading = isContentLoading ? (
        <ContentWrapper>
                { instance.renderHeading() }
        </ContentWrapper>
    ) : '';

    return (
        <main block="CartPage" aria-label="Cart Page">
                { loadingHeading }
                { content }
        </main>
    );
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            render,
            renderContent
        }
    }
};
