const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        hideMainLevel: state.SideMenuReducer.hideMainLevel,
        isSubMenuActive: state.SideMenuReducer.isSubMenuActive,
        showMainLevel: state.SideMenuReducer.showMainLevel,
        getPromoImage: state.SideMenuReducer.getPromoImage,
        promoImage: state.SideMenuReducer.promoImage,
        isSideMenuOpen: state.SideMenuReducer.isSideMenuOpen
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        closeSideMenu,
        hideMainLevel,
        isSubMenuActive,
        showMainLevel,
        getPromoImage,
        promoImage,
        isSideMenuOpen
    } = instance.props;

    return {
        ...callback.apply(instance, args),
        closeSideMenu,
        hideMainLevel,
        isSubMenuActive,
        showMainLevel,
        getPromoImage,
        promoImage,
        isSideMenuOpen
    };
};

export default {
    'Component/Menu/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/Menu/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/MenuItem/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/MenuItem/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
