/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import { lazyComponentLoader } from 'Util/lazyComponentLoader';

export const PRIVACY_SETTINGS = 'privacy-settings';
export const PRIVACY_SETTINGS_URL = '/privacy-settings';

export const MyAccountPrivacySettings = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */ '../component/MyAccountPrivacySettings'
), 2);

const renderMap = (originalMember) => ({
    ...originalMember,
    [PRIVACY_SETTINGS]: MyAccountPrivacySettings
});

const tabMap = (originalMember) => ({
    ...originalMember,
    [PRIVACY_SETTINGS]: {
        url: PRIVACY_SETTINGS_URL,
        name: __('Privacy Settings')
    }
});

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': { renderMap }
    }
};
