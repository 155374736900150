const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        isSubMenuActive: state.SideMenuReducer.isSubMenuActive,
        showMainLevel: state.SideMenuReducer.showMainLevel,
        setPromoImage: state.SideMenuReducer.setPromoImage
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        hideMainLevel,
        showMainLevel,
        isSubMenuActive,
        setPromoImage,
        getPromoImage
    } = instance.props;

    return {
        ...callback.apply(instance, args),
        hideMainLevel,
        showMainLevel,
        isSubMenuActive,
        setPromoImage,
        getPromoImage
    };
};

export default {
    'Component/Menu/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/MenuItem/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/MenuItem/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
