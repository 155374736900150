/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import componentLoader from 'Util/componentLoader';

const SideMenuDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/SideMenu/SideMenu.dispatcher'
), 2);

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        closeSideMenu: () => SideMenuDispatcher.then(
            ({ default: dispatcher }) => dispatcher.closeSideMenu(dispatch)
        ),
        hideMainLevel: () => SideMenuDispatcher.then(
            ({ default: dispatcher }) => dispatcher.hideMainLevel(dispatch)
        ),
        showMainLevel: () => SideMenuDispatcher.then(
            ({ default: dispatcher }) => dispatcher.showMainLevel(dispatch)
        ),
        getPromoImage: () => SideMenuDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getPromoImage(dispatch)
        )
    };
};

export default {
    'Component/MenuItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/Menu/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
