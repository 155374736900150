/* eslint-disable max-lines */
import { setAdyenCCisLoading } from 'Store/Checkout/Checkout.action';
import scrollToError from 'Util/Form/Form';
import { fetchQuery } from 'Util/Request';
import { isEqual } from 'Util/Variants';

import { ADYEN_CC, CREDIT_CARD_TYPE } from '../component/AdyenCC/AdyenCC.config';
import { ADYEN_HPP } from '../component/AdyenHPP/AdyenHPP.config';
import AdyenQuery from '../query/Adyen.query';
import { getCcCodeByAltCodeUtil } from '../util/GetCcCodeByAltCode.util';

import '@adyen/adyen-web/dist/adyen.css';

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        setAdyenCCisLoading: (payload) => dispatch(setAdyenCCisLoading(payload))
    };
};

const getPaymentMethods = (instance, paymentMethods, adyenObject) => {
    const {
        paymentMethodsResponse:
        { paymentMethods: adyenMethods = [], storedPaymentMethods = [] } = {}, paymentMethodsExtraDetails = {}
    } = adyenObject || {};
    const paymentMethodsContainHPP = paymentMethods.some(({ code }) => code === ADYEN_HPP);

    if (!paymentMethodsContainHPP) {
        return paymentMethods;
    }

    const newMethods = [...paymentMethods, ...adyenMethods].reduce((methods, method) => {
        if (method.code === ADYEN_HPP || method.type === CREDIT_CARD_TYPE) {
            return methods;
        }

        if (method.code === ADYEN_CC && method.brand) {
            const { brands = [] } = adyenMethods.find((cart) => cart.type === CREDIT_CARD_TYPE);
            return [...methods, {
                ...method,
                type: CREDIT_CARD_TYPE,
                details: storedPaymentMethods.find(({ type }) => type === CREDIT_CARD_TYPE),
                brands,
                onSubmit: (event) => {
                    event.preventDefault();
                    return false;
                }
            }];
        }

        if ('type' in method) {
            if (method.type === 'applepay' && (location.protocol !== 'https:' || !window.ApplePaySession)) {
                return methods;
            }

            return [
                ...methods,
                {
                    ...method,
                    code: ADYEN_HPP,
                    title: method.name,
                    details: storedPaymentMethods.find(({ type }) => type === method.type) || [],
                    config: paymentMethodsExtraDetails[method.type],
                    onSubmit: (event) => {
                        event.preventDefault();
                        return false;
                    }
                }
            ];
        }

        return [...methods, method];
    }, []);

    instance.setState({ newMethods });
};

const containerProps = (args, callback, instance) => {
    const { newMethods = [] } = instance.state;

    return {
        ...callback(...args),
        paymentMethods: newMethods
    };
};

const scrollToErrorField = (instance) => {
    const fields = [];
    const { checkoutFormRef } = instance.props;
    const validation = checkoutFormRef.current.containerFunctions.validate(false, false);
    // Iterate through each element in the form
    Array.from(checkoutFormRef.current.formRef.elements).forEach((element) => {
        // Construct the field object
        const field = {
            name: element.name,
            type: element.type,
            value: element.value,
            field: element
        };

        // Push the field object to the fieldsArray
        fields.push(field);
    });

    scrollToError(fields, validation);
};

const initializeAdyen = async (instance, adyenMethods) => {
    const { isAdyenLoaded } = instance.state;
    if (!adyenMethods || isAdyenLoaded) {
        return;
    }

    instance.setState({ isAdyenLoaded: true });

    const { paymentMethodsResponse } = adyenMethods;
    const {
        handleDetails, setAdyenCCisLoading, checkoutFormRef, setAdyenState
    } = instance.props;
    const { getAdyenConfig } = await fetchQuery(AdyenQuery.getConfig());
    const {
        originKey,
        mode: environment,
        locale
    } = getAdyenConfig || {};

    setAdyenCCisLoading(true);
    window.adyen = await import(
        /* webpackMode: "lazy", webpackChunkName: "checkout-adyen" */ '@adyen/adyen-web'
    ).then(({ default: AdyenCheckout }) => {
        const configuration = {
            paymentMethodsResponse, // The `/paymentMethods` response from the server.
            clientKey: originKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
            locale,
            environment,
            onClick: (resolve, reject) => {
                if (checkoutFormRef.current.containerFunctions.validate(false, false) === true) {
                    resolve();
                    return false;
                }
                reject();
                return true;
            },
            onChange: (state, dropin) => {
                setAdyenState(state.data, dropin);
                instance.setState({
                    adyenState: state.data
                });
                const buttonBilling = window.document.getElementById('CheckoutBilling-Button');
                if (buttonBilling) {
                    buttonBilling.removeAttribute('disabled');
                }
            },
            onSubmit: (state, dropin) => {
                if (state.isValid) {
                    setAdyenState(state.data, dropin);
                    instance.setState({
                        adyenState: state.data
                    });
                }
                // Sorry but we need this to trigger native button submit event
                const buttonBilling = window.document.getElementById('CheckoutBilling-Button');
                if (buttonBilling) {
                    buttonBilling.removeAttribute('disabled');
                    buttonBilling.click();
                }
            },
            onAdditionalDetails: ({ data }, dropin) => {
                handleDetails(data);
            },
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    enableStoreDetails: false,
                    hideCVC: false,
                    name: 'Credit or debit card'
                },
                paywithgoogle: {
                    onClick: (resolve, reject) => {
                        if (checkoutFormRef.current.containerFunctions.validate(false, false) === true) {
                            resolve();
                            return true;
                        }
                        scrollToErrorField(instance);
                        reject();
                        return false;
                    }
                },
                googlepay: {
                    onClick: (resolve, reject) => {
                        if (checkoutFormRef.current.containerFunctions.validate(false, false) === true) {
                            resolve();
                            return true;
                        }
                        scrollToErrorField(instance);
                        reject();
                        return false;
                    }
                },
                applepay: {
                    onClick: (resolve, reject) => {
                        if (checkoutFormRef.current.containerFunctions.validate(false, false) === true) {
                            resolve();
                            return true;
                        }
                        scrollToErrorField(instance);
                        reject();
                        return false;
                    }
                },
                ideal: {
                    onSubmit: (state, component) => {
                        if (checkoutFormRef.current.containerFunctions.validate(false, false) === true) {
                            if (state.isValid) {
                                const { clientStateDataIndicator, paymentMethod: { type } } = state.data;
                                setAdyenState({ clientStateDataIndicator, paymentMethod: { type } }, component);
                                instance.setState({
                                    adyenState: { clientStateDataIndicator, paymentMethod: { type } }
                                });
                            }

                            // Sorry but we need this to trigger native button submit event
                            const buttonBilling = window.document.getElementById('CheckoutBilling-Button');
                            if (buttonBilling) {
                                buttonBilling.removeAttribute('disabled');
                                buttonBilling.click();
                            }

                            return true;
                        }
                        scrollToErrorField(instance);
                        component.update({ issuer: state.data.paymentMethod.issuer });
                        return false;
                    }
                },
                trustly: {
                    onSubmit: (state, component) => {
                        if (checkoutFormRef.current.containerFunctions.validate(false, false) === true) {
                            if (state.isValid) {
                                const { clientStateDataIndicator, paymentMethod: { type } } = state.data;
                                setAdyenState({ clientStateDataIndicator, paymentMethod: { type } }, component);
                                instance.setState({
                                    adyenState: { clientStateDataIndicator, paymentMethod: { type } }
                                });
                            }

                            // Sorry but we need this to trigger native button submit event
                            const buttonBilling = window.document.getElementById('CheckoutBilling-Button');
                            if (buttonBilling) {
                                buttonBilling.removeAttribute('disabled');
                                buttonBilling.click();
                            }

                            return true;
                        }

                        scrollToErrorField(instance);
                        component.update();
                        return false;
                    }
                },
                mobilepay: {
                    onSubmit: (state, component) => {
                        if (checkoutFormRef.current.containerFunctions.validate(false, false) === true) {
                            if (state.isValid) {
                                const { clientStateDataIndicator, paymentMethod: { type } } = state.data;
                                setAdyenState({ clientStateDataIndicator, paymentMethod: { type } }, component);
                                instance.setState({
                                    adyenState: { clientStateDataIndicator, paymentMethod: { type } }
                                });
                            }
                            // Sorry but we need this to trigger native button submit event
                            const buttonBilling = window.document.getElementById('CheckoutBilling-Button');
                            if (buttonBilling) {
                                buttonBilling.removeAttribute('disabled');
                                buttonBilling.click();
                            }

                            return true;
                        }

                        scrollToErrorField(instance);
                        component.update();
                        return false;
                    }
                },
                clearpay: {
                    onSubmit: (state, component) => {
                        if (checkoutFormRef.current.containerFunctions.validate(false, false) === true) {
                            if (state.isValid) {
                                const { clientStateDataIndicator, paymentMethod: { type } } = state.data;
                                setAdyenState({ clientStateDataIndicator, paymentMethod: { type } }, component);
                                instance.setState({
                                    adyenState: { clientStateDataIndicator, paymentMethod: { type } }
                                });
                            }
                            // Sorry but we need this to trigger native button submit event
                            const buttonBilling = window.document.getElementById('CheckoutBilling-Button');
                            if (buttonBilling) {
                                buttonBilling.removeAttribute('disabled');
                                buttonBilling.click();
                            }

                            return true;
                        }

                        scrollToErrorField(instance);
                        component.update();
                        return false;
                    }
                }
            }
        };

        return AdyenCheckout(configuration);
    });
    setAdyenCCisLoading(false);
};

const _getPaymentData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;
    const { email } = instance.props;

    if (code === ADYEN_CC) {
        const [fields, asyncData] = args;
        const { adyenState = {} } = instance.state;
        const { additional_data: extraData } = asyncData ? asyncData[0] : {
            additional_data: {
                guestEmail: email,
                combo_card_type: 'credit',
                is_active_payment_token_enabler: false,
                number_of_installments: ''
            }
        };
        const stateData = JSON.stringify(adyenState);
        const cc_type = getCcCodeByAltCodeUtil(adyenState.brand);

        const additional_data = {
            ...extraData, stateData, cc_type
        };

        return { code, additional_data };
    }
    if (code === ADYEN_HPP) {
        const [fields, asyncData] = args;
        const { adyenState = instance.state } = instance.state;
        const { paymentMethod: { type } = {} } = adyenState;
        const { additional_data: extraData } = asyncData ? asyncData[0] : {
            additional_data: {
                guestEmail: email,
                brand_code: type
            }
        };

        const stateData = JSON.stringify(adyenState);

        const additional_data = {
            ...extraData, stateData
        };

        return { code, additional_data };
    }

    return callback(...args);
};

const componentDidUpdate = (args, callback, instance) => {
    const { paymentMethods, adyenPaymentMethods } = instance.props;
    const [prevProps] = args;

    const { paymentMethods: prevPaymentMethods, adyenPaymentMethods: prevAdyenPaymentMethods } = prevProps;

    if (!isEqual(prevPaymentMethods, paymentMethods)
        || !isEqual(prevAdyenPaymentMethods, adyenPaymentMethods)) {
        getPaymentMethods(instance, paymentMethods, adyenPaymentMethods);
        initializeAdyen(instance, adyenPaymentMethods);
    }

    callback(...args);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            componentDidUpdate,
            containerProps
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            _getPaymentData
        }
    },
    'Component/CheckoutBilling/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
