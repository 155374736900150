/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import {
    FORGOT_PASSWORD as FORGOT_PASSWORD_PN,
    LOGIN as LOGIN_PN,
    REGISTER as REGISTER_PN
} from 'SourceComponent/Header/Header.config';
import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from 'SourceRoute/UrlRewrites/UrlRewrites.config';
import { appendWithStoreCode, getStorePrefix } from 'Util/Url';

const {
    bundle: BUNDLE,
    configurable: CONFIGURABLE,
    downloadable: DOWNLOADABLE,
    grouped: GROUPED,
    simple: SIMPLE,
    virtual: VIRTUAL
} = PRODUCT_TYPE;

export const PAGE_FOR_ALL = 'all';

export const ANCHOR_CATEGORY_GROUP = 'anchor_categories';
export const NOT_ANCHOR_CATEGORY_GROUP = 'notanchor_categories';
export const CATEGORY_PAGE_GROUPS = ['anchor_categories', 'notanchor_categories'];

/** @namespace LayoutUpdates/Hooks/UseLayoutUpdate/useCategoryLayoutUpdate */
export const useCategoryLayoutUpdate = (layoutUpdate) => {
    const type = useSelector((state) => state.UrlRewritesReducer?.pageType);
    const category = useSelector((state) => state.CategoryReducer?.category);
    const {
        page_group, page_for, entities
    } = layoutUpdate;

    if (!category || !type) {
        return false;
    }

    if (CATEGORY_PAGE_GROUPS.indexOf(page_group) === -1) {
        return false;
    }
    // ^^^ if this is not category layout update, ignore

    if (type.toUpperCase() !== TYPE_CATEGORY) {
        return false;
    }
    // ^^^ if current URL is not category URL, ignore

    const { id: categoryId, is_anchor: isAnchor } = category;

    if (page_group === ANCHOR_CATEGORY_GROUP && !isAnchor) {
        return false;
    }
    if (page_group === NOT_ANCHOR_CATEGORY_GROUP && isAnchor) {
        return false;
    }
    // ^^^ respect select category anchor option
    if (page_for === PAGE_FOR_ALL) {
        return true;
    }
    // ^^^ if layout update is for all pages, render

    return categoryId && entities.split(',').indexOf(categoryId.toString()) !== -1;
    // ^^^ if layout update is for current category, render
};

export const PRODUCT_PAGE_GROUPS = [
    'all_products',
    'simple_products',
    'virtual_products',
    'bundle_products',
    'downloadable_products',
    // 'giftcard_products',
    'configurable_products',
    'grouped_products'
];

export const SIMPLE_PRODUCT_PAGE_GROUP = 'simple_products';
export const VIRTUAL_PRODUCT_PAGE_GROUP = 'virtual_products';
export const BUNDLE_PRODUCT_PAGE_GROUP = 'bundle_products';
export const DOWNLOADABLE_PRODUCT_PAGE_GROUP = 'downloadable_products';
// const GIFTCARD_PRODUCT_PAGE_GROUP = 'giftcard_products';
export const CONFIGURABLE_PRODUCT_PAGE_GROUP = 'configurable_products';
export const GROUPED_PRODUCT_PAGE_GROUP = 'grouped_products';

/** @namespace LayoutUpdates/Hooks/UseLayoutUpdate/useProductLayoutUpdate */
export const useProductLayoutUpdate = (layoutUpdate) => {
    const type = useSelector((state) => state.UrlRewritesReducer?.pageType);
    const product = useSelector((state) => state.ProductReducer?.product);
    const { page_group, page_for, entities } = layoutUpdate;

    if (!product || !type) {
        return false;
    }

    if (PRODUCT_PAGE_GROUPS.indexOf(page_group) === -1) {
        return false;
    }
    // ^^^ if this is not product layout update, ignore

    if (type.toUpperCase() !== TYPE_PRODUCT) {
        return false;
    }
    // ^^^ if current URL is not product URL, ignore

    const { type_id: productType, id: productId } = product;
    if (page_group === SIMPLE_PRODUCT_PAGE_GROUP && productType !== SIMPLE) {
        return false;
    }
    if (page_group === VIRTUAL_PRODUCT_PAGE_GROUP && productType !== VIRTUAL) {
        return false;
    }
    if (page_group === BUNDLE_PRODUCT_PAGE_GROUP && productType !== BUNDLE) {
        return false;
    }
    if (page_group === DOWNLOADABLE_PRODUCT_PAGE_GROUP && productType !== DOWNLOADABLE) {
        return false;
    }
    // if (page_group === GIFTCARD_PRODUCT_PAGE_GROUP && productType !== GIFTCARD) return false;

    if (page_group === CONFIGURABLE_PRODUCT_PAGE_GROUP && productType !== CONFIGURABLE) {
        return false;
    }
    if (page_group === GROUPED_PRODUCT_PAGE_GROUP && productType !== GROUPED) {
        return false;
    }
    // ^^^ respect select product type option

    if (page_for === PAGE_FOR_ALL) {
        return true;
    }
    // ^^^ if layout update is for all pages, render

    return productId && entities.split(',').indexOf(productId.toString()) !== -1;
    // ^^^ if layout update is for current product, render
};

export const PAGE_PAGE_GROUP = 'pages';
export const ALL_PAGES_GROUP = 'all_pages';

export const HOME_PN = '/';
export const HOME_LH = ['cms_index_defaultindex', 'cms_index_index'];
export const CMS_LH = ['cms_page_view'];
// ^^^ CMS

export const NOT_FOUND_LH = ['cms_index_defaultnoroute', 'cms_index_noroute'];
// ^^^ 404

export const FORGOT_PASSWORD_LH = ['customer_account_forgotpassword', 'customer_account_createpassword'];
export const LOGIN_LH = ['customer_account_login', 'customer_account_logoutsuccess'];
export const REGISTER_LH = ['customer_account_create'];
export const CONFIRM_LH = ['customer_account_confirmation'];
export const CONFIRM_PN = '/account/confirm';
export const MY_ACCOUNT_PN = '/my-account';
export const MY_ACCOUNT_LH = ['customer_account_index', 'customer_account_edit'];
export const MY_ADDRESS_PN = '/my-account/address-book';
export const MY_ADDRESS_LH = ['customer_address_form', 'customer_address_index'];
export const MY_ACCOUNT_NEWS_PN = '/my-account/newsletter-subscription';
export const MY_ACCOUNT_NEWS_LH = ['newsletter_manage_index'];
// ^^^ Account

export const SEARCH_PN = '/search';
export const SEARCH_LH = ['catalogsearch_result_index', 'catalogsearch_advanced_index', 'catalogsearch_advanced_result'];
// ^^^ Search

export const PLP_LH = ['catalog_category_view', 'catalog_category_view_type_layered'];
// ^^^ PDP

export const PDP_LH = ['catalog_product_gallery', 'catalog_product_view', 'catalog_product_view_type_configurable'];
// ^^^ PLP

export const COMPARE_PN = '/compare';
export const COMPARE_LH = ['catalog_product_compare_index'];
// ^^^ Compare

export const WISHLIST_PN = '/my-account/wishlist';
export const WISHLIST_LH = ['wishlist_index_index', 'wishlist_index_share', 'wishlist_shared_index', 'wishlist_index_configure'];
// ^^^ Wishlist

export const DOWNLOADABLE_PN = '/my-account/my-downloadable';
export const DOWNLOADABLE_LH = ['downloadable_customer_products'];
// ^^^ Downloadable

export const CART_PN = '/cart';
export const CART_LH = ['checkout_cart_index', 'checkout_cart_configure'];
export const CHECKOUT_SHIPPING_PN = '/checkout/shipping';
export const CHECKOUT_SHIPPING_LH = ['checkout_onepage_shippingmethod'];
export const CHECKOUT_PAYMENT_PN = '/checkout/billing';
export const CHECKOUT_PAYMENT_LH = ['checkout_onepage_paymentmethod'];
export const CHECKOUT_SUCCESS_PN = '/checkout/success';
export const CHECKOUT_SUCCESS_LH = ['checkout_onepage_failure', 'checkout_onepage_success', 'checkout_onepage_review'];
export const CHECKOUT_PN = '/checkout';
export const CHECKOUT_LH = ['checkout_index_index'];
// ^^^ Cart, Checkout

/** @namespace LayoutUpdates/Hooks/UseLayoutUpdate/usePageLayoutUpdate */
export const usePageLayoutUpdate = (layoutUpdate) => {
    const type = useSelector((state) => state.UrlRewritesReducer?.pageType);
    const { pathname } = useLocation() || window.location;

    // TODO: add support for store-prefixed URLs
    const { page_group, layout_handle: lh } = layoutUpdate;
    if (page_group === ALL_PAGES_GROUP) {
        return true;
    }
    // ^^^ if layout update is for all pages, render

    if (page_group !== PAGE_PAGE_GROUP) {
        return false;
    }
    // ^^^ if this is not page layout update, ignore

    const foramtedPath = pathname.endsWith('/') ? pathname : `${pathname}/`;
    if (HOME_LH.includes(lh) && (foramtedPath !== getStorePrefix())) {
        return false;
    }
    if (CMS_LH.includes(lh) && type.toUpperCase() !== TYPE_CMS_PAGE) {
        return false;
    }
    // ^^^ CMS paths

    if (NOT_FOUND_LH.includes(lh) && type.toUpperCase() !== TYPE_NOTFOUND) {
        return false;
    }
    // ^^^ 404 page

    if (FORGOT_PASSWORD_LH.includes(lh) && pathname !== FORGOT_PASSWORD_PN) {
        return false;
    }
    if (LOGIN_LH.includes(lh) && pathname !== LOGIN_PN) {
        return false;
    }
    if (REGISTER_LH.includes(lh) && pathname !== REGISTER_PN) {
        return false;
    }
    if (MY_ACCOUNT_LH.includes(lh) && pathname !== MY_ACCOUNT_PN) {
        return false;
    }
    if (CONFIRM_LH.includes(lh) && pathname !== CONFIRM_PN) {
        return false;
    }
    if (MY_ADDRESS_LH.includes(lh) && pathname !== MY_ADDRESS_PN) {
        return false;
    }
    if (MY_ACCOUNT_NEWS_LH.includes(lh) && pathname !== MY_ACCOUNT_NEWS_PN) {
        return false;
    }
    // ^^^ My Account paths

    if (SEARCH_LH.includes(lh) && !pathname.includes(SEARCH_PN)) {
        return false;
    }
    // ^^^ Search paths

    if (PLP_LH.includes(lh) && type.toUpperCase() !== TYPE_CATEGORY) {
        return false;
    }
    // ^^^ PDP

    if (PDP_LH.includes(lh) && type.toUpperCase() !== TYPE_PRODUCT) {
        return false;
    }
    // ^^^ PLP

    if (COMPARE_LH.includes(lh) && pathname !== COMPARE_PN) {
        return false;
    }
    // ^^^ Compare

    if (WISHLIST_LH.includes(lh) && pathname !== WISHLIST_PN) {
        return false;
    }
    // ^^^ Wishlist

    if (DOWNLOADABLE_LH.includes(lh) && pathname !== DOWNLOADABLE_PN) {
        return false;
    }
    // ^^^ Downloadable

    if (CART_LH.includes(lh) && pathname !== CART_PN) {
        return false;
    }
    if (CHECKOUT_PAYMENT_LH.includes(lh) && pathname !== CHECKOUT_PAYMENT_PN) {
        return false;
    }
    if (CHECKOUT_SHIPPING_LH.includes(lh) && pathname !== CHECKOUT_SHIPPING_PN) {
        return false;
    }

    if (CHECKOUT_SUCCESS_LH.includes(lh) && pathname !== appendWithStoreCode(CHECKOUT_SUCCESS_PN)) {
        return false;
    }
    if (CHECKOUT_LH.includes(lh) && !pathname.includes(CHECKOUT_PN)) {
        return false;
    }
    // ^^^ Cart, Checkout

    return true;
};

/** @namespace LayoutUpdates/Hooks/UseLayoutUpdate/useLayoutUpdate */
export const useLayoutUpdate = (layoutUpdate) => {
    const isCatLU = useCategoryLayoutUpdate(layoutUpdate);
    const isProdLU = useProductLayoutUpdate(layoutUpdate);
    const isPageLU = usePageLayoutUpdate(layoutUpdate);

    return isCatLU || isProdLU || isPageLU;
};

/** @namespace LayoutUpdates/Hooks/UseLayoutUpdate/useBlockReference */
export const useBlockReference = (br) => {
    const layoutUpdates = useSelector((state) => state.ConfigReducer.layoutUpdates);
    return layoutUpdates.filter(({ block_reference: luBr }) => br === luBr);
};
