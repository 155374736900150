import ResetButton from 'Component/ResetButton';

const removeSeoParams = (history, suffix, pathname, filter_key) => {
    const seoPart = pathname.split('/').pop();
    const pathWithoutSeoPart = pathname.replace(`${filter_key}/${seoPart}`, '');
    history.push(`${pathWithoutSeoPart}${suffix}`);
};

const resetFilters = (args, callback, instance) => {
    const {
        seoFilterParams, history, suffix, location: { pathname }, filter_key
    } = instance.props;

    callback(...args);

    if (seoFilterParams && seoFilterParams.length) {
        removeSeoParams(history, suffix, pathname, filter_key);
    }
};

const renderResetButton = (args, callback, instance) => {
    const {
        onSeeResultsClick, seoFilterParams, suffix, filter_key
    } = instance.props;

    return (
        <ResetButton
          seoFilterParams={ seoFilterParams }
          suffix={ suffix }
          filter_key={ filter_key }
          onClick={ onSeeResultsClick }
          mix={ { block: 'CategoryFilterOverlay', elem: 'ResetButton' } }
        />
    );
};

export default {
    'Component/ResetButton/Container': {
        'member-function': {
            resetFilters
        }
    },
    'Component/CategoryFilterOverlay/Component': {
        'member-function': {
            renderResetButton
        }
    }
};
