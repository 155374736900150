/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { addBlockReferenceAfter } from '../../component/LayoutUpdate';
import { BR_PRODUCT_IMAGE } from '../../util/blocks';

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductGallery: addBlockReferenceAfter(BR_PRODUCT_IMAGE)
        }
    }
};
