import { CHECK_MONEY } from 'Component/CheckoutPayments/CheckoutPayments.config';

import { PAGE_TYPE_CHECKOUT } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    EVENT_GTM_DYNAMIC_RETARGETING
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event, setPageType } from '../store/GoogleTagManager/GoogleTagManager.action';

const onBillingSuccess = (args, callback, instance) => {
    const { showErrorNotification } = instance.props;
    const { paymentMethod } = instance.state;

    if (paymentMethod === CHECK_MONEY) {
        const notification = [];
        notification.push({ message: __('Please select a valid payment method!') });
        showErrorNotification(notification);
        return;
    }

    callback.apply(instance, args);
};

const componentDidMount = (args, callback, instance) => {
    const { event, setPageType } = instance.props;

    setPageType(PAGE_TYPE_CHECKOUT);
    event(EVENT_GTM_DYNAMIC_RETARGETING, PAGE_TYPE_CHECKOUT);

    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        setPageType: (pageType) => dispatch(setPageType(pageType))
    };
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess,
            componentDidMount
        }
    },
    'Component/CheckoutBilling/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
