/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { addBlockReferenceBeforeWrapper } from '../../component/LayoutUpdate';
import { BR_REVIEW_FORM_FIELDS_BEFORE } from '../../util/blocks';

export default {
    'Component/ProductReviews/Component': {
        'member-function': {
            render: addBlockReferenceBeforeWrapper(BR_REVIEW_FORM_FIELDS_BEFORE)
        }
    }
};
