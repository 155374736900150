/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import componentLoader from 'Util/componentLoader';

const SideMenuDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/SideMenu/SideMenu.dispatcher'
), 2);

const MobileSearchBarDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/MobileSearchBar/MobileSearchBar.dispatcher'
), 2);

export const SearchBarDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SearchBar/SearchBar.dispatcher'
), 2);

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        openSideMenu: () => SideMenuDispatcher.then(
            ({ default: dispatcher }) => dispatcher.openSideMenu(dispatch)
        ),
        updateLoadStatus: (options) => SearchBarDispatcher.then(
            ({ default: dispatcher }) => dispatcher.onError(options, dispatch)
        ),
        clearSearchResults: () => SearchBarDispatcher.then(
            ({ default: dispatcher }) => dispatcher.clearSearchResults(dispatch)
        ),
        activateSearchBar: () => MobileSearchBarDispatcher.then(
            ({ default: dispatcher }) => dispatcher.activateSearchBar(dispatch)
        ),
        deactivateSearchBar: () => MobileSearchBarDispatcher.then(
            ({ default: dispatcher }) => dispatcher.deactivateSearchBar(dispatch)
        )
    };
};

export default {
    'Component/Header/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/SearchOverlay/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
