import { ROOT, SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH
} from '../component/GoogleTagManager/GoogleTagManager.events';

const state = (original) => ({
    ...original,
    fired: false,
    lastSearch: ''
});

const renderItems = (args, callback, instance) => {
    const {
        items,
        event,
        location: {
            pathname
        }
    } = instance.props;

    // const { fired = false } = instance.state;

    const isSearch = pathname.includes(SEARCH);

    if (isSearch) {
        instance.setState({ fired: true });
        const search = pathname.split(ROOT).pop();

        event(EVENT_GTM_IMPRESSIONS_SEARCH, {
            products: items, list: SEARCH, search
        });
    }

    return callback(...args);
};

const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const {
        items,
        event,
        mix: {
            block = ''
        },
        location: {
            pathname,
            search
        }
    } = instance.props;

    const { fired = false } = instance.state;
    const {
        items: prevItems
    } = prevProps || {};

    const isSearch = pathname.includes(SEARCH);
    if (!isSearch && items !== prevItems && !fired) {
        if (!(Object.keys(items).length === 0)) {
            instance.setState({ fired: true });
            event(EVENT_GTM_IMPRESSIONS_PLP, {
                products: items, list: block, pathname, search
            });
        }
    }

    return callback(...args);
};

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            // renderListPageItems,
            renderItems,
            componentDidUpdate
        },
        'member-property': {
            state
        }
    }
};
